import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4f98bc",
  },
  paper: {
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      "& .rdrDefinedRangesWrapper": {
        display: "none",
      },
    },
  },
  paperLabor: {
    backgroundColor: "#ffffff",
    "& .rdrDefinedRangesWrapper": {
      display: "none",
    },
    "& .rdrDayDisabled span": {
      filter: "none",
    },
  },
  popup: {
    zIndex: 100,
    marginRight: "16px",
  },
  dateBtnWrap: {
    display: "inline-block",
    marginTop: 0,
  },
  tableFilterBtn: {
    textTransform: "none",
    fontWeight: "bold",
    marginTop: 0,
  },
  calendarFilterBtn: {
    textTransform: "none",
    color: "#4f98bc",
    backgroundColor: "#EDF5F8",
    border: "1px solid #EDF5F8",
    width: 120,
    fontSize: 14,
    paddingTop: 8,
    paddingBottom: 8,
    fontWeight: 600,
    "& svg": {
      marginRight: 8,
      width: 16,
      top: -2,
      position: "relative",
    },
  },
  filter: {
    display: "inline-block",
  },
  filterTooltip: {
    zIndex: 3,
  },
  filterPopper: {
    width: "100%",
    maxWidth: 420,
    padding: theme.spacing(2),
  },
  filterDivider: {
    borderTop: "solid 1px #f8f8f8",
  },
  filterCheck: {
    padding: "0 5px 0 0",
  },
  filterLabel: {
    fontSize: "14px",
  },
  filterGroup: {
    borderBottom: "solid 1px #f8f8f8",
    paddingBottom: 10,
    marginBottom: 10,
  },
  filterActions: {
    display: "flex",
    padding: "0 12px 10px 12px",
  },
  filterActionsRight: {
    flex: "1 1",
    textAlign: "right",
  },
  filterAlert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  containerAlert: {
    fontSize: 11,
    maxWidth: 320,
    marginLeft: 8,
    border: "none",
  },
  badgeDate: {
    backgroundColor: "#4f98bc",
    color: "#ffffff",
    borderRadius: 10,
    padding: "0 5px",
    marginLeft: 4,
    fontSize: 11,
    width: 24,
    fontWeight: 600,
  },
  buttonWithIcon: {
    display: "flex",
    textTransform: "none",
    width: 120,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#EDF5F8",
    color: "#4f98bc",
    fontSize: 14,
  },
  chipIcon: {
    backgroundColor: "#4f98bc",
    color: "#ffffff",
    width: 23,
    height: 16,
    fontSize: 11,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 8,
  },
}));

export default useStyles;
