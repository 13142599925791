import React from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import AccountPayableTable from "./accountPayablesTable";
import Box from "@material-ui/core/Box";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { BillingProvider } from "contexts/billingApContext";

import useStyles from "./styles";
import { WorkticketModalProvider } from "../reportAccountPayableWorkticketsInternal/context/WorkticketModalContext";
import { AddCommentDialogProvider } from "../reportAccountPayableWorkticketsInternal/context/AddCommentDialogueContext";
import { AddDiscountDialogProvider } from "../reportAccountPayableWorkticketsInternal/context/AddDiscountDialogueContext";

const AccountPayablesList = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageHeader
        pageTitle="Account Payable"
        link="/financial/report/account-payable"
      />
      <Box className={classes.pageBodyWrapper}>
        <BillingProvider>
          {/*<WorkticketModalProvider>*/}
          {/*    <AddCommentDialogProvider>*/}
          {/*        <AddDiscountDialogProvider>*/}
          <WorkticketModalProvider>
            <AddCommentDialogProvider>
              <AddDiscountDialogProvider>
                <AccountPayableTable />
              </AddDiscountDialogProvider>
            </AddCommentDialogProvider>
          </WorkticketModalProvider>
        </BillingProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(AccountPayablesList);
