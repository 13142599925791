import React, { useState, useEffect } from "react";
import * as classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import useStyles from "./styles";
import InputAdornment from "@material-ui/core/InputAdornment";

const FormInput = (props) => {
  const classes = useStyles();
  const {
    name,
    label,
    value,
    type,
    handleBlur,
    blurType,
    error,
    gridSizes,
    internal,
    readOnly,
    readonly,
    styleOverride,
    validateOnType,
    sameLine,
    noMargin,
    alignText,
    withoutLabel,
    onChange,
    custom,
    endText,
    isObjectEndText,
    subLabel,
    placeholder,
    isCustomObjectEndText,
    hideLabel,
  } = props;
  const [stateValue, setStateValue] = React.useState("");
  const [gridSizeMd, setGridSizeMd] = useState(8);
  const [gridSizeXs] = useState(12);
  let errorInput = undefined;
  if (error && error.length > 0) {
    errorInput = error.filter((item) => item.key === name);
  }

  useEffect(() => {
    if (gridSizes) {
      gridSizes.map((sizes) => {
        switch (sizes.size) {
          case "md":
            return setGridSizeMd(sizes.val);
          default:
            return 12;
        }
      });
    }
  }, [gridSizes]);

  useEffect(() => {
    if (value !== undefined) {
      setStateValue(value);
    }
  }, [value]);

  const handleChange = (event) => {
    setStateValue(event.target.value);
    if (validateOnType) {
      validateOnType(event.target);
    }
  };

  return (
    <div
      className={
        noMargin
          ? classes.noMargin
          : internal && sameLine
          ? custom
            ? classes.inputSameRowCustom
            : classes.inputSameRow
          : internal
          ? classes.inputInternalContainer
          : classes.inputContainer
      }
    >
      <Grid container spacing={1}>
        {!withoutLabel && (
          <Grid item xs={12} md={12} className={classes.labelContainer}>
            <InputLabel
              htmlFor={name}
              className={
                internal ? classes.inputInternalLabel : classes.inputLabel
              }
            >
              {hideLabel ? " " : label}
            </InputLabel>
            {subLabel ? (
              <InputLabel
                htmlFor={name}
                className={
                  internal
                    ? classes.inputInternalSubLabel
                    : classes.inputSubLabel
                }
              >
                {subLabel}
              </InputLabel>
            ) : null}
          </Grid>
        )}
        <Grid item xs={gridSizeXs} md={gridSizeMd}>
          <Input
            name={name}
            id={name}
            fullWidth={true}
            disableUnderline={true}
            className={classNames(classes.inputField, {
              // [classes.disabled]: readonly
            })}
            classes={{
              focused: classes.inputFieldFocus,
              disabled: classes.inputFieldDisabled,
              input:
                alignText === "right"
                  ? classes.inputFieldRight
                  : alignText === "center"
                  ? classes.inputFieldCenter
                  : "",
            }}
            inputProps={{
              autoComplete: "new-password",
              style: {
                cursor: "pointer",
              },
            }}
            multiline={props.multiline ?? false}
            endAdornment={props.endAdornment}
            // startAdornment={props.startAdornment}
            startAdornment={
              <InputAdornment position="start">
                {props.startAdornment ? props.startAdornment : null}
              </InputAdornment>
            }
            rows={props.rows ?? 1}
            placeholder={placeholder}
            value={stateValue}
            readOnly={readOnly ? readOnly : false}
            onChange={onChange ?? handleChange}
            style={styleOverride}
            onBlur={(event) =>
              handleBlur
                ? handleBlur(event.target, blurType ? blurType : "normal")
                : () => {}
            }
            // disabled={readonly ? readonly : false}
            disabled={props.disabled}
            type={type ? type : "text"}
          />
          {errorInput && Boolean(errorInput.length) && (
            <FormHelperText error>{errorInput[0].message}</FormHelperText>
          )}
        </Grid>
        {gridSizeMd < 12 ? (
          <Grid
            item
            md={12 - gridSizeMd}
            className={
              isCustomObjectEndText
                ? classes.endTextContainer3
                : isObjectEndText
                ? classes.endTextContainer2
                : classes.endTextContainer
            }
          >
            {endText}
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

export default FormInput;
