import React from "react";
import * as classNames from "classnames";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListSubheader from "@material-ui/core/ListSubheader";
import { useTheme } from "@material-ui/core/styles";
import { VariableSizeList } from "react-window";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import EmergencyIcon from "@material-ui/icons/NewReleases";
import { default as useStyles } from "./styles";
import Typography from "@material-ui/core/Typography";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LISTBOX_PADDING = 8;

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
      lineHeight: "120%",
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 48 : 60;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 60;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="div"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const renderGroup = (params) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

const SelectJobsAuto = (props) => {
  const classes = useStyles();
  const {
    name,
    label,
    value,
    defaultValue,
    options,
    multiple,
    error,
    internal,
    readonly,
    handleChange,
    labelError,
    disableCloseOnSelect,
    inputContainerProps,
    limitTags,
    onClose,
  } = props;

  let errorInput = undefined;
  if (error && error.length > 0) {
    errorInput = error.filter((item) => item.key === name);
  }

  return (
    <div
      className={
        inputContainerProps ? inputContainerProps : classes.inputContainer
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Typography
            htmlFor={name}
            className={
              internal ? classes.inputInternalLabel : classes.inputLabel
            }
          >
            {labelError ? <EmergencyIcon className={classes.errorIcon} /> : ""}
            {label}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          {multiple ? (
            <Autocomplete
              multiple={true}
              id={name}
              options={options}
              defaultValue={defaultValue ?? ""}
              className={classNames(classes.inputAutoField)}
              getOptionLabel={(option) => {
                return option.job_number && option.job_number !== "-1"
                  ? `#${option.job_number} - ${option.job_description}`
                  : option.job_description;
              }}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 3 }}
                    checked={selected}
                  />
                  {option.job_number && option.job_number !== "-1"
                    ? `#${option.job_number}  - ${option.job_description}`
                    : option.job_description}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={name}
                  id={name}
                  fullWidth={true}
                  className={classNames(classes.inputField)}
                />
              )}
              onChange={handleChange}
              disabled={readonly ?? false}
              value={value ?? null}
              limitTags={limitTags ?? 3}
              disableCloseOnSelect
              onClose={onClose}
              ListboxComponent={ListboxComponent}
              renderGroup={renderGroup}
            />
          ) : (
            <Autocomplete
              id={name}
              options={options}
              defaultValue={defaultValue ?? ""}
              getOptionLabel={(option) =>
                option.id >= 0
                  ? `#${option.job_number}  - ${option.job_description}`
                  : option.job_description
              }
              className={classNames(classes.inputAutoField)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={name}
                  id={name}
                  fullWidth={true}
                  className={classNames(classes.inputField)}
                />
              )}
              onChange={handleChange}
              disabled={readonly ?? false}
              value={value ?? null}
              disableCloseOnSelect={disableCloseOnSelect}
              onClose={onClose}
              ListboxComponent={ListboxComponent}
              renderGroup={renderGroup}
            />
          )}
          {errorInput && Boolean(errorInput.length) && (
            <FormHelperText error>{errorInput[0].message}</FormHelperText>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default SelectJobsAuto;
