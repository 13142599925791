import React, { useState, useEffect, useMemo } from "react";
import { Box, Button } from "@material-ui/core";
import { logException } from "components/util/logUtil";
import { getTrades, getStates, getCompanies } from "services/partnerService";
import { useFilterState } from "contexts/filterContext";
import {
  partnerStatus as partnerStatusData,
  partnerRatingFilter as ratingData,
  insuranceComplianceFilter as insuranceComplianceData,
  ndaSignedFilter as ndaSignedData,
  ownershipOptions,
} from "./constants";
import useStyles from "./../partnersList/styles";
import WorkticketFiltersPopover from "./WorkticketFilterPopover";
import { getFilterJobs } from "../../components/util/filterUtil";

const localStorageFilters = localStorage.getItem("filter_partners");

const countAppliedFilters = (filters) => {
  if (!Array.isArray(filters)) {
    console.log("Filters is not an array or is null, returning 0.");
    return 0;
  }

  return filters.reduce(
    (count, filter) =>
      Array.isArray(filter.values) ? count + filter.values.length : count,
    0
  );
};

const useLoadData = (loadFunction, dataKey, logMessage, noDataProp) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await loadFunction();
        if (noDataProp) {
          // setData(
          //   response.map((item) => ({
          //     value: item.id || item.abbreviation || item.company_name,
          //     label:
          //       item.trade_name ||
          //       item.name ||
          //       item.company_name ||
          //       item.job_description,
          //   }))
          // );
          setData([]);
        } else {
          setData(
            response.data.data[dataKey].map((item) => ({
              value: item.id || item.abbreviation || item.company_name,
              label:
                item.trade_name ||
                item.name ||
                item.company_name ||
                item.job_description,
            }))
          );
        }
      } catch (e) {
        logException(logMessage, e);
      }
    };

    loadData();
  }, [loadFunction, dataKey, logMessage]);

  return data;
};

const WorkticketFilters = () => {
  const [jobs, setJobs] = useState([]);
  // useEffect(() => {
  //   const loadJob = async () => {
  //     try {
  //       const resultForRender = await getFilterJobs();
  //       console.log("resultForRender", resultForRender);
  //       setJobs(resultForRender);
  //     } catch (e) {
  //       console.log("Cannot load jobs data");
  //     }
  //   };
  //   if (anchorEl && !jobs.length) {
  //     loadJob();
  //   }
  // }, []);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { filters } = useFilterState();

  // const companyData = useLoadData(
  //   getCompanies,
  //   "company_names",
  //   "Cannot load company filters:"
  // );
  // const statesData = useLoadData(
  //   getStates,
  //   "states",
  //   "Cannot load states filters:"
  // );
  // const tradesData = useLoadData(
  //   getTrades,
  //   "trades",
  //   "Cannot load trades filters:"
  // );
  const jobsData = useLoadData(
    getFilterJobs,
    "jobs",
    "Cannot load jobs filters:",
    true
  );
  const filterCount = useMemo(() => {
    const stored = JSON.parse(localStorageFilters) || [];
    return countAppliedFilters(filters || stored);
  }, [filters]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setPopoverOpen(true);
  };

  const handleClose = () => {
    setPopoverOpen(false);
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button className={classes.filterBtn} onClick={handleClick}>
        Filters
        {/*<span className={classes.badgeFilters}>{filterCount || 0}</span>*/}
      </Button>
      <WorkticketFiltersPopover
        anchorEl={anchorEl}
        open={popoverOpen}
        onClose={handleClose}
        statusOptions={partnerStatusData}
        // companyOptions={companyData}
        ratingOptions={ratingData}
        // stateOptions={statesData}
        jobOptions={jobsData}
        // tradeOptions={tradesData}
        insuranceComplianceOptions={insuranceComplianceData}
        ndaSignedOptions={ndaSignedData}
        ownershipOptions={ownershipOptions}
      />
    </Box>
  );
};

export default WorkticketFilters;
