import Dialog from "@material-ui/core/Dialog";
import React from "react";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import useStyles from "./styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Box } from "@material-ui/core";
import { ReceiptIcon } from "./icons/ReceiptIcon";
import Typography from "@material-ui/core/Typography";
import { OutlineReceiptIcon } from "./icons/OutlineReceiptIcon";
import { WorkticketIcon } from "./icons/WorkticketIcon";
import Table from "../../components/common/Table/table";
import { useWorkticketModal } from "./context/WorkticketModalContext";
export function WorkticketModal({
  columns,
  handleRowClick,
  handleScrollClick,
}) {
  const [workticketModalAction, dispatchWorkticketModal] = useWorkticketModal();
  const { isWorkticketModalOpen } = workticketModalAction;
  const classes = useStyles();
  function toggleWorkticketModal(open) {
    dispatchWorkticketModal({ type: "TOGGLE_WORKTICKET_MODAL", open: open });
  }
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  return (
    <>
      <Dialog open={true} fullScreen={true} TransitionComponent={Transition}>
        <Box className={classes.workticketModalMainContainer}>
          <Box className={classes.appBarWorkticketModalContainer}>
            <Box className={classes.workticketPreviewButtonContainer}>
              <Box className={classes.workticketPreviewTitle}>
                <WorkticketIcon />
                <Typography className={classes.workticketPreviewTitleText}>
                  Workticket Preview
                </Typography>
              </Box>
              <Button
                className={classes.viewAllWorkticketsButton}
                onClick={() => {}}
              >
                View All Worktickets
              </Button>
            </Box>

            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                toggleWorkticketModal(false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Table
            handleRowClick={handleRowClick}
            newColumns={columns}
            handleScrollClick={handleScrollClick}
            activeMobile={true}
          >
            {/*{filterState.filters && <FiltersAppliedList hideDate={true} />}*/}
          </Table>{" "}
        </Box>
      </Dialog>
    </>
  );
}
