import React, { Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import { useAddDiscountModal } from "./context/AddDiscountDialogueContext";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import AddContactIcon from "../partnerPage/icons/AddContactIcon";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "./styles";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { ReceiptIcon } from "./icons/ReceiptIcon";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import FormInput from "../../components/ui/FormContent/formInput";

export function AddDiscountDialogue() {
  const classes = useStyles();
  const [discountAction, dispatchDiscountAction] = useAddDiscountModal();
  const { isOpenAddDialog } = discountAction;
  const toggleModal = (open) => {
    dispatchDiscountAction({ type: "TOGGLE_CONTACT_DIALOG", open });
  };
  return (
    <Fragment>
      <Dialog open={isOpenAddDialog}>
        <DialogTitle
          className={classes.dialogTitleWrapper}
          id="form-dialog-title"
        >
          <Box className={classes.dialogTitleInnerWrapper}>
            <ReceiptIcon />
            <Typography className={classes.dialogTitle}>
              Add new Discount
            </Typography>
          </Box>
          <IconButton
            onClick={() => {
              toggleModal(false);
            }}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.addContactFormWrapper}>
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="discount_percentage"
            label="Discount Percentage*"
            // value={data.phone_number}
            // handleBlur={handleBlurPhoneNumber}
            // error={phoneErrors}
            type="text"
            placeholder="Write a discount Percentage"
          />
        </DialogContent>
        <DialogActions className={classes.actionItemButton}>
          <Button
            className={classes.addDiscountCancelButton}
            // onClick={() => handleDispatchAddContact(false)}
            // disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            className={classes.secondAddDiscountButton}
            // disabled={isSubmitting}
            // onClick={handleSubmit}
          >
            {/*{isEditing ? "Edit" : "Add"} Contact*/}
            Add Discount
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
