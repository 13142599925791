import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import { useWorkticketModal } from "./context/WorkticketModalContext";
export function VariousTextComponent({ id }) {
  const [workticketModalAction, dispatchWorkticketModal] = useWorkticketModal();
  function toggleWorkticketModal(open) {
    dispatchWorkticketModal({ type: "TOGGLE_WORKTICKET_MODAL", open: open });
  }
  const classes = useStyles();
  return (
    <>
      <Typography
        onClick={() => {
          toggleWorkticketModal(true);
        }}
        className={classes.variousText}
      >
        Various
      </Typography>
    </>
  );
}
