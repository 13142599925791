import React from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import Box from "@material-ui/core/Box";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { ListProvider } from "contexts/listContext";

import useStyles from "./styles";
import { BillingProvider } from "../../contexts/billingApContext";
import AccountPayableWorkticketTable from "./AccountPayableWorkticketTable";
import { AddDiscountDialogProvider } from "./context/AddDiscountDialogueContext";
import { AddCommentDialogProvider } from "./context/AddCommentDialogueContext";
import { WorkticketModalProvider } from "./context/WorkticketModalContext";

const AccountPayablesWorkticketListInternal = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageHeader
        pageTitle="Account Payable"
        link="/financial/report/account-payable-internal-workticket"
      ></PageHeader>
      <Box className={classes.pageBodyWrapper}>
        <BillingProvider>
          <WorkticketModalProvider>
            <AddCommentDialogProvider>
              <AddDiscountDialogProvider>
                <AccountPayableWorkticketTable />
              </AddDiscountDialogProvider>
            </AddCommentDialogProvider>
          </WorkticketModalProvider>
        </BillingProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(AccountPayablesWorkticketListInternal);
