import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import React from "react";
import { CommentItem } from "../partnerPage/Comments/CommentItem";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const StyledTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
    marginBottom: 4,
  },
  indicator: {
    display: "flex",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 70,
      width: "100%",
      backgroundColor: "#4F98BC",
    },
  },
})((props) => (
  <Tabs
    orientation={"horizontal"}
    {...props}
    TabIndicatorProps={{ children: <span /> }}
  />
));

const StyledTab = withStyles((theme) => ({
  root: {
    marginRight: theme.spacing(0),
    fontWeight: theme.typography.fontWeightRegular,
    minWidth: "90px",
    textAlign: "center",
    alignItems: "center",
    "&. MuiTab-root": {
      minWidth: "90px",
    },
    "&:hover": {
      color: "#4F98BC",
      opacity: 1,
    },
    "&$selected": {
      color: "#4F98BC",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#4F98BC",
    },
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: "flex-start",
      fontWeight: 600,
    },
  },
  selected: {},
}))((props) => <Tab {...props} />);
export function AccountReceivablePayable() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Box className={classes.payableReceivableMainContainer}>
        <StyledTabs
          value={value}
          onChange={handleChangeTab}
          aria-label="styled tabs"
        >
          <StyledTab label="Account Receivable" {...a11yProps(0)} />
          <StyledTab label="Account Payable" {...a11yProps(1)} />
        </StyledTabs>
        <TabPanel value={value} index={1}>
          <Box className={classes.infoAccountContainer}>
            <Box className={classes.labelsContainer}>
              <Typography className={classes.labelBoldText}>
                Customer:
              </Typography>
              <Typography className={classes.labelInfoValueText}>
                ABC Company
              </Typography>
            </Box>
            <Box className={classes.labelsContainer}>
              <Typography className={classes.labelBoldText}>
                Payment:
              </Typography>
              <Typography className={classes.labelInfoValueText}>
                $63.00
              </Typography>
            </Box>
            <Box className={classes.labelsContainer}>
              <Typography className={classes.labelBoldText}>
                Discount:
              </Typography>
              <Typography className={classes.labelInfoValueText}>5%</Typography>
            </Box>
            <Box className={classes.labelsContainer}>
              <Typography className={classes.labelBoldText}>
                Total After Discount:
              </Typography>
              <Typography className={classes.labelGreenText}>$61.75</Typography>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={0}>
          <Box className={classes.infoAccountContainer}>
            <Box className={classes.labelsContainer}>
              <Typography className={classes.labelBoldText}>
                Customer:
              </Typography>
              <Typography className={classes.labelInfoValueText}>
                ABC Company
              </Typography>
            </Box>
            <Box className={classes.labelsContainer}>
              <Typography className={classes.labelBoldText}>
                Payment:
              </Typography>
              <Typography className={classes.labelInfoValueText}>
                $63.00
              </Typography>
            </Box>
            <Box className={classes.labelsContainer}>
              <Typography className={classes.labelBoldText}>
                Discount:
              </Typography>
              <Typography className={classes.labelInfoValueText}>5%</Typography>
            </Box>
            <Box className={classes.labelsContainer}>
              <Typography className={classes.labelBoldText}>
                Total After Discount:
              </Typography>
              <Typography className={classes.labelGreenText}>$61.75</Typography>
            </Box>
          </Box>
        </TabPanel>
      </Box>
    </>
  );
}
