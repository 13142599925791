import React, { useMemo } from "react";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import useStyles from "./styles";

import { useFilterState, useFilterDispatch } from "contexts/filterContext";

const FiltersApplied = (props) => {
  const classes = useStyles();
  const {
    filters,
    startDateActive,
    endDateActive,
    startDate,
    endDate,
    breakdown,
  } = useFilterState();
  const dispatchFilters = useFilterDispatch();

  const handleDelete = (filter, item, depended = null) => {
    const { value, id } = item;
    const findType = filters.find((item) => item.filter === filter);
    const findTypeIndex = filters.findIndex((item) => item.filter === filter);
    if (findType) {
      const indexFound = findType.values.findIndex(
        (itemI) => itemI.value === value
      );
      findType.values.splice(indexFound, 1);
      filters.splice(findTypeIndex, 1);
      if (filter === "job" || filter === "job_number") {
        if (id) {
          findType.values = findType.values.filter(
            (itemI) => Number(itemI?.parent_job_id) !== Number(id)
          );
        }
      }
      if (findType.values.length) {
        filters.push(findType);
      }
    }

    let filterResult = filters;
    if (depended) {
      filterResult = filters.filter((item) => !depended.includes(item.filter));
    }
    dispatchFilters({
      type: "SET_FILTERS_CORE",
      filters: [...filterResult],
    });
    localStorage.setItem(
      `filter_${window.location.pathname.split("/")[1]}`,
      JSON.stringify([...filterResult])
    );
  };

  const handleDateStartDelete = () => {
    dispatchFilters({
      type: "CLEAR_START_DATE",
    });
    dispatchFilters({
      type: "CLEAR_END_DATE",
    });
    localStorage.removeItem(
      `filter_date_${window.location.pathname.split("/")[1]}`
    );
  };

  const handleBreakdownDelete = () => {
    dispatchFilters({
      type: "SET_BREAKDOWN",
      breakdown: null,
    });
  };

  const countChildJobs = useMemo(() => {
    let count = 0;
    filters
      .filter((item) => item.group === "Job")
      .forEach((filter) => {
        filter.values.forEach((item) => {
          if (item.label.includes("(Child)")) {
            count++;
          }
        });
      });
    return count;
  }, [filters]);

  const countAllJobs = useMemo(() => {
    let count = 0;
    filters
      .filter((item) => item.group === "Job")
      .forEach((filter) => {
        filter.values.forEach((item) => {
          count++;
        });
      });
    return count;
  }, [filters]);

  return (
    <Box className={classes.filtersApplied}>
      {(filters.length > 0 || startDateActive || endDateActive) &&
        "Filters Applied:"}
      {filters
        .filter((itemF) => !Boolean(itemF.hidden))
        .map((filter) => {
          return filter.values
            .filter(
              (item) =>
                !item.label.includes("(Child)") ||
                countAllJobs === countChildJobs
            )
            .map((item) => {
              return item.disableDelete ? (
                <Chip
                  key={`${filter.filter}_${item.value}`}
                  size="small"
                  label={
                    <Box>
                      <b>{filter.group}</b>: {item.label} {}
                    </Box>
                  }
                  color="secondary"
                  variant="outlined"
                  className={classes.filterChip}
                />
              ) : (
                <Chip
                  key={`${filter.filter}_${item.value}`}
                  size="small"
                  label={
                    <Box>
                      <b>{filter.group}</b>: {item.label}
                    </Box>
                  }
                  onDelete={() =>
                    handleDelete(filter.filter, item, filter.depended)
                  }
                  color="secondary"
                  variant="outlined"
                  className={classes.filterChip}
                />
              );
            });
        })}
      {countChildJobs > 0 && countAllJobs > countChildJobs && (
        <Chip
          size="small"
          label={
            <Box>
              <b>+{countChildJobs} Child Jobs</b>
            </Box>
          }
          color="secondary"
          variant="outlined"
          className={classes.filterChip}
        />
      )}

      {startDateActive && !props.hideDate && (
        <Chip
          size="small"
          label={
            <Box>
              {moment(startDate).format("L")} - {moment(endDate).format("L")}
            </Box>
          }
          onDelete={handleDateStartDelete}
          color="secondary"
          variant="outlined"
          className={classes.filterChip}
        />
      )}
      {breakdown ? (
        <Chip
          size="small"
          label={
            <Box>
              <b>Breakdown</b>: {breakdown}
            </Box>
          }
          onDelete={handleBreakdownDelete}
          color="secondary"
          variant="outlined"
          className={classes.filterChip}
        />
      ) : null}
      {props.children}
    </Box>
  );
};

export default FiltersApplied;
