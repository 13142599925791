export function IconCalendar() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.75 2.70283H15.5C16.325 2.70283 17 3.38132 17 4.21059V16.2727C17 17.1019 16.325 17.7804 15.5 17.7804H3.5C2.675 17.7804 2 17.1019 2 16.2727V4.21059C2 3.38132 2.675 2.70283 3.5 2.70283H4.25V1.19507H5.75V2.70283H13.25V1.19507H14.75V2.70283ZM3.5 16.2727H15.5V6.47223H3.5V16.2727Z"
        fill="#4F98BC"
      />
      <mask
        id="mask0_148_8905"
        maskUnits="userSpaceOnUse"
        x="2"
        y="1"
        width="15"
        height="17"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.75 2.70283H15.5C16.325 2.70283 17 3.38132 17 4.21059V16.2727C17 17.1019 16.325 17.7804 15.5 17.7804H3.5C2.675 17.7804 2 17.1019 2 16.2727V4.21059C2 3.38132 2.675 2.70283 3.5 2.70283H4.25V1.19507H5.75V2.70283H13.25V1.19507H14.75V2.70283ZM3.5 16.2727H15.5V6.47223H3.5V16.2727Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_148_8905)"></g>
    </svg>
  );
}
