import React, { Fragment, useState } from "react";
import { Box } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ThreeDots from "../partnerPage/icons/ThreeDots";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import EditIcon from "@material-ui/icons/Edit";
import { Location } from "../partnerPage/icons/Location";
import { DeleteBin } from "../partnerPage/icons/DeleteBin";
import useStyles from "./styles";
import { OutlineReceiptIcon } from "./icons/OutlineReceiptIcon";
import { CommentBubbleOutlineIcon } from "./icons/CommentBubbleOutlineIcon";
import { useAddCommentModal } from "./context/AddCommentDialogueContext";
import { useAddDiscountModal } from "./context/AddDiscountDialogueContext";
import { useWorkticketModal } from "./context/WorkticketModalContext";
export function WorkticketMenuActions() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [workticketModalAction, dispatchWorkticketModal] = useWorkticketModal();
  const { isWorkticketModalOpen } = workticketModalAction;
  function toggleWorkticketModal(open) {
    dispatchWorkticketModal({ type: "TOGGLE_WORKTICKET_MODAL", open: open });
  }
  const [commentAction, dispatchCommentAction] = useAddCommentModal();
  const toggleModal = (open) => {
    dispatchCommentAction({ type: "TOGGLE_COMMENT_DIALOG", open });
  };
  const [discountAction, dispatchDiscountAction] = useAddDiscountModal();
  const { isOpenAddDialog } = discountAction;
  const toggleDiscountModal = (open) => {
    dispatchDiscountAction({ type: "TOGGLE_CONTACT_DIALOG", open });
  };
  const menuItems = [
    {
      label: "View WT",
      icon: <RemoveRedEyeOutlinedIcon fontSize="inherit" />,
      onClick: () => {
        // handleToggleDrawer("right", true, true, row, true);
        toggleWorkticketModal(true);
        handleMenuClose();
      },
      // disabled: isSubmitting,
    },
    {
      label: "Add Discount",
      icon: <OutlineReceiptIcon fontSize="inherit" />,
      onClick: () => {
        // handleToggleDrawer("right", true, true, row);
        toggleDiscountModal(true);
        handleMenuClose();
      },
    },
    {
      label: "Edit Discount",
      icon: <EditIcon fontSize="inherit" />,
      onClick: () => {
        // handleToggleDrawer("right", true, true, row);
        // handleMenuClose();
      },
    },
    {
      label: "Add a Comment",
      icon: <CommentBubbleOutlineIcon fontSize="inherit" />,
      onClick: () => {
        // handleToggleDrawer("right", true, true, row);
        toggleModal(true);
        handleMenuClose();
      },
    },
    {
      label: "Delete Discount",
      icon: <DeleteBin fontSize="inherit" />,
      onClick: () => {
        // handleDeleteUserDialogueOpen();
        // handleMenuClose();
      },
      // disabled: isSubmitting,
    },
  ];
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <Fragment>
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          handleMenuClick(event);
        }}
        onFocus={(event) => event.stopPropagation()}
        aria-label="options"
      >
        <ThreeDots />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {menuItems.map(({ label, icon, onClick, disabled }) => (
          <MenuItem key={label} onClick={onClick} disabled={disabled}>
            <Box className={classes.menuIcon}>{icon}</Box>
            <Typography variant="caption" className={classes.menuText}>
              {label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}
