import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import Popover from "@material-ui/core/Popover";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ApplyButton from "./applyButton";
import CancelButton from "./cancelButton";
import ClearButton from "./clearButton";
import { defaultStaticRanges } from "./defaultRanges";
import { parseISO } from "date-fns";

import useStyles from "./calendarFilters.styles";
import "./styles.css";
import "./theme.css";

import { useFilterState, useFilterDispatch } from "contexts/filterContext";
import { IconCalendar } from "./IconCalendar";

const DateRangePickerComponent = ({ newStyle, chipActive }) => {
  const classes = useStyles();
  const moduleName = window.location.pathname.split("/")[1];
  const filterDatePersisted = JSON.parse(
    localStorage.getItem(`filter_date_${moduleName}`)
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDateCalendar, setStartDateCalendar] = useState(null);
  const [endDateCalendar, setEndDateCalendar] = useState(null);
  const { startDate, endDate, key } = useFilterState();
  const dispatchFilters = useFilterDispatch();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (filterDatePersisted) {
      setStartDateCalendar(parseISO(filterDatePersisted?.startDate));
      setEndDateCalendar(parseISO(filterDatePersisted?.endDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (event) => {
    setStartDateCalendar(new Date(startDate));
    setEndDateCalendar(new Date(endDate));
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setStartDateCalendar(new Date(startDate));
    setEndDateCalendar(new Date(endDate));
    setAnchorEl(null);
  };

  const handleRangeChange = (payload) => {
    setStartDateCalendar(payload.selection.startDate);
    setEndDateCalendar(payload.selection.endDate);
  };

  const handleDateApply = () => {
    const dataDate = {
      startDate: startDateCalendar,
      endDate: endDateCalendar,
    };
    dispatchFilters({
      type: "SET_DATE",
      ...dataDate,
    });
    localStorage.setItem(
      `filter_date_${window.location.pathname.split("/")[1]}`,
      JSON.stringify(dataDate)
    );
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleClear = () => {
    dispatchFilters({
      type: "CLEAR_DATE_RANGE",
    });
    localStorage.removeItem(
      `filter_date_${window.location.pathname.split("/")[1]}`
    );
    setAnchorEl(null);
  };

  return (
    <div className={classes.dateBtnWrap}>
      {newStyle && (
        <Button
          startIcon={<IconCalendar />}
          className={classes.buttonWithIcon}
          aria-describedby={id}
          onClick={handleClick}
        >
          Date
          {chipActive && <Box className={classes.chipIcon}>1</Box>}
        </Button>
      )}
      {!newStyle && (
        <Button
          className={classes.tableFilterBtn}
          aria-describedby={id}
          onClick={handleClick}
        >
          Date <ExpandMore />
        </Button>
      )}

      <Popover
        id={2}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.filterTooltip}
      >
        <Paper className={classes.paper}>
          <DateRangePicker
            onChange={handleRangeChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            className={"PreviewArea"}
            months={1}
            showMonthAndYearPickers={false}
            ranges={[
              { startDate: startDateCalendar, endDate: endDateCalendar, key },
            ]}
            direction="horizontal"
            rangeColors={["#3A95C1"]}
            inputRanges={[]}
            staticRanges={defaultStaticRanges}
          />
          <Box className={classes.filterActions}>
            <Box className={classes.filterActionsRight}>
              <ClearButton onClick={handleClear} />
              <CancelButton onClick={handleCancel} />
              <ApplyButton onClick={handleDateApply} />
            </Box>
          </Box>
        </Paper>
      </Popover>
    </div>
  );
};

export default DateRangePickerComponent;
