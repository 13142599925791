export function WorkticketIcon() {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 18.5L0 20V0L1.5 1.5L3 0L4.5 1.5L6 0L7.5 1.5L9 0L10.5 1.5L12 0L13.5 1.5L15 0L16.5 1.5L18 0V20L16.5 18.5L15 20L13.5 18.5L12 20L10.5 18.5L9 20L7.5 18.5L6 20L4.5 18.5L3 20L1.5 18.5ZM15 5V7H3V5H15ZM3 9V11H15V9H3ZM3 13V15H15V13H3Z"
        fill="white"
      />
      <mask
        id="mask0_148_11184"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 18.5L0 20V0L1.5 1.5L3 0L4.5 1.5L6 0L7.5 1.5L9 0L10.5 1.5L12 0L13.5 1.5L15 0L16.5 1.5L18 0V20L16.5 18.5L15 20L13.5 18.5L12 20L10.5 18.5L9 20L7.5 18.5L6 20L4.5 18.5L3 20L1.5 18.5ZM15 5V7H3V5H15ZM3 9V11H15V9H3ZM3 13V15H15V13H3Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_148_11184)">
        <rect x="-17" y="-23" width="64" height="64" fill="#4D4D4D" />
      </g>
    </svg>
  );
}
