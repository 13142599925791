import React, { Fragment, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "./styles";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { ReceiptIcon } from "./icons/ReceiptIcon";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import FormInput from "../../components/ui/FormContent/formInput";
import { useAddCommentModal } from "./context/AddCommentDialogueContext";
import ThreeDots from "../partnerPage/icons/ThreeDots";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import { OutlineReceiptIcon } from "./icons/OutlineReceiptIcon";
import EditIcon from "@material-ui/icons/Edit";
import { CommentBubbleOutlineIcon } from "./icons/CommentBubbleOutlineIcon";
import { DeleteBin } from "../partnerPage/icons/DeleteBin";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";

export function AddWorkticketCommentDialogue() {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [commentAction, dispatchCommentAction] = useAddCommentModal();
  const { isOpenAddDialog } = commentAction;
  const toggleModal = (open) => {
    dispatchCommentAction({ type: "TOGGLE_COMMENT_DIALOG", open });
  };
  const menuItems = [
    {
      label: "Edit Comment",
      icon: <EditIcon fontSize="inherit" />,
      onClick: () => {
        // handleToggleDrawer("right", true, true, row);
        setIsEditing(true);
        handleMenuClose();
      },
    },
    {
      label: "Delete Comment",
      icon: <DeleteBin fontSize="inherit" />,
      onClick: () => {
        // handleDeleteUserDialogueOpen();
        // handleMenuClose();
      },
      // disabled: isSubmitting,
    },
  ];
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const EditingComponent = () => {
    return (
      <>
        <Box className={classes.commentContainer}>
          <Box className={classes.profilePicture}></Box>
          <Box className={classes.nameMenuActionsContainer}>
            <Typography className={classes.editCommentTitleText}>
              Alice Keys
            </Typography>
          </Box>
          <TextField
            className={classes.commentEditField}
            classes={{ root: classes.questionTitleEdit }}
            autoFocus={true}
            InputProps={{ disableUnderline: true }}
            // defaultValue={question.question.question}
            multiline
            minRows={4}
          >
            Hi! Andrew Gosling I really liked the north shot of the building, is
            there a chance that we can get a south shot as well?
          </TextField>
          <Box className={classes.editCommentButtonsContainer}>
            <Button
              className={classes.addDiscountCancelButton}
              // onClick={() => handleDispatchAddContact(false)}
              // disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              className={classes.secondAddDiscountButton}
              // disabled={isSubmitting}
              // onClick={handleSubmit}
            >
              {/*{isEditing ? "Edit" : "Add"} Contact*/}
              Update Comment
            </Button>
          </Box>
        </Box>
      </>
    );
  };
  return (
    <Fragment>
      <Dialog open={isOpenAddDialog}>
        <DialogTitle
          className={classes.dialogTitleWrapperComment}
          id="form-dialog-title"
        >
          <Box className={classes.commentTitleInnerWrapper}>
            <Box className={classes.titleIconWorkticketID}>
              <ReceiptIcon />
              <Typography className={classes.dialogTitle}>
                Workticket - 203738
              </Typography>
            </Box>
            <Typography className={classes.addCommentTitle}>
              Add a comment
            </Typography>
          </Box>
          <IconButton
            onClick={() => {
              toggleModal(false);
            }}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.addContactFormWrapper}>
          {!isEditing && (
            <Box className={classes.commentContainer}>
              <Box className={classes.profilePicture}></Box>
              <Box className={classes.nameMenuActionsContainer}>
                <Typography className={classes.userTitleText}>
                  Alice Keys
                </Typography>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    handleMenuClick(event);
                  }}
                  onFocus={(event) => event.stopPropagation()}
                  aria-label="options"
                >
                  <ThreeDots></ThreeDots>
                </IconButton>
              </Box>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {menuItems.map(({ label, icon, onClick, disabled }) => (
                  <MenuItem key={label} onClick={onClick} disabled={disabled}>
                    <Box className={classes.menuIcon}>{icon}</Box>
                    <Typography variant="caption" className={classes.menuText}>
                      {label}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
              <Typography className={classes.bodyCommentText}>
                Hi! Andrew Gosling I really liked the north shot of the
                building, is there a chance that we can get a south shot as
                well?
              </Typography>
            </Box>
          )}
          {isEditing && <EditingComponent />}
          <TextField
            // className={classes.commentEditField}
            classes={{ root: classes.writeCommentField }}
            autoFocus={true}
            InputProps={{ disableUnderline: true }}
            // defaultValue={question.question.question}
            placeholder={"Write a comment"}
            multiline
            minRows={4}
          >
            Hi! Andrew Gosling I really liked the north shot of the building, is
            there a chance that we can get a south shot as well?
          </TextField>
        </DialogContent>
        <DialogActions className={classes.actionItemButton}>
          <Button
            className={classes.addDiscountCancelButton}
            onClick={() => toggleModal(false)}
            // disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            className={classes.secondAddDiscountButton}
            // disabled={isSubmitting}
            // onClick={handleSubmit}
          >
            {/*{isEditing ? "Edit" : "Add"} Contact*/}
            Add Discount
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
