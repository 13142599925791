import React, { useState, useEffect } from "react";
import { Popover, Button, Box, Typography } from "@material-ui/core";

import { useFilterDispatch } from "contexts/filterContext";
import useStyles from "../partnersList/styles";
import FilterAccordion from "../partnersList/partnersFilterAccordion";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const FILTER_KEY = `filter_${window.location.pathname.split("/")[1]}`;

const initialFilterState = {
  status: [],
  rating: [],
  states: [],
  trades: [],
  company_name: [],
  insurance_compliance: [],
  nda_signed: [],
  minority_owned: [],
  woman_owned: [],
  disability_owned: [],
  veteran_owned: [],
};

const initialToggleFilterState = {
  insurance_compliance: [],
  nda_signed: [],
  minority_owned: [],
  woman_owned: [],
  disability_owned: [],
  veteran_owned: [],
};

const WorkticketFiltersPopover = ({
  anchorEl,
  open,
  onClose,
  statusOptions,
  companyOptions,
  ratingOptions,
  stateOptions,
  tradeOptions,
  insuranceComplianceOptions,
  ndaSignedOptions,
  ownershipOptions,
}) => {
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const classes = useStyles();
  const dispatchFilters = useFilterDispatch();
  const [filterSelections, setFilterSelections] = useState(initialFilterState);
  const [toggleFilterSelections, setToggleFilterSelections] = useState(
    initialToggleFilterState
  );

  useEffect(() => {
    if (open) {
      const storedFilters = localStorage.getItem(FILTER_KEY);
      if (storedFilters) {
        const filters = JSON.parse(storedFilters);
        const filterState = { ...initialFilterState };
        const toggleState = { ...initialToggleFilterState };

        if (Array.isArray(filters)) {
          filters.forEach(({ filter, values }) => {
            if (filterState.hasOwnProperty(filter)) {
              filterState[filter] = values;
            } else if (toggleState.hasOwnProperty(filter)) {
              toggleState[filter] = values;
            }
          });
        } else {
          console.error("Stored filters is not an array:", filters);
        }

        setFilterSelections(filterState);
        setToggleFilterSelections(toggleState);
      }
    }
  }, [open]);

  useEffect(() => {
    setToggleFilterSelections((prevState) => ({
      ...prevState,
      ...filterSelections,
    }));
  }, [filterSelections]);

  const handleApply = () => {
    const filterData = Object.entries(filterSelections).reduce(
      (acc, [key, values]) => {
        if (values.length) {
          acc.push({
            filter: key,
            group: key,
            values,
          });
        }
        return acc;
      },
      []
    );

    dispatchFilters({
      type: "SET_FILTERS_CORE",
      filters: filterData,
    });

    localStorage.setItem(FILTER_KEY, JSON.stringify(filterData));
    onClose();
  };

  const handleClear = () => {
    setFilterSelections(initialFilterState);
    setToggleFilterSelections(initialToggleFilterState);
    dispatchFilters({ type: "SET_FILTERS_CORE", filters: [] });

    localStorage.removeItem(FILTER_KEY);
    onClose();
  };

  const handleClose = () => {
    const storedFilters = localStorage.getItem(FILTER_KEY);
    if (storedFilters) {
      const filters = JSON.parse(storedFilters);
      const filterState = { ...initialFilterState };
      const toggleState = { ...initialToggleFilterState };

      filters.forEach(({ filter, values }) => {
        if (filterState.hasOwnProperty(filter)) {
          filterState[filter] = values;
        } else if (toggleState.hasOwnProperty(filter)) {
          toggleState[filter] = values;
        }
      });

      setFilterSelections(filterState);
      setToggleFilterSelections(toggleState);
    } else {
      setFilterSelections(initialFilterState);
      setToggleFilterSelections(initialToggleFilterState);
    }
    onClose();
  };

  const handleFilterChange = (filterName, selectedOptions) => {
    setFilterSelections((prevState) => ({
      ...prevState,
      [filterName]: selectedOptions,
    }));
  };

  const handleToggleChange = (filterName, newGroupValue) => {
    setToggleFilterSelections((prevState) => ({
      ...prevState,
      [filterName]: newGroupValue,
    }));
    handleFilterChange(filterName, newGroupValue);
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Box className={classes.filterContainer}>
        <Box className={classes.subContainer}>
          <Box className={classes.filterHeader}>
            <Typography variant="h5" className={classes.headerTitle}>
              Filters
            </Typography>
            <Box>
              <Button onClick={handleClose} className={classes.buttonCancel}>
                Cancel
              </Button>
              <Button onClick={handleClear} className={classes.buttonCancel}>
                Clear
              </Button>
              <Button onClick={handleApply} className={classes.buttonApply}>
                Apply
              </Button>
            </Box>
          </Box>

          <Box className={classes.accordionContainer}>
            {[
              {
                name: "company_name",
                title: "Customer",
                data: companyOptions,
                searchEnabled: true,
                searchPlaceholder: "Write a name...",
              },
              {
                name: "states",
                title: "Jobs",
                data: stateOptions,
                searchEnabled: true,
                searchPlaceholder: "Find a state...",
              },
              {
                name: "minority_owned",
                title: "Fully Compliant",
                data: ownershipOptions,
                toggleButtonsEnabled: true,
              },
              {
                name: "woman_owned",
                title: "Discount Applied",
                data: ownershipOptions,
                toggleButtonsEnabled: true,
              },
            ].map(
              ({
                name,
                title,
                data,
                searchEnabled = false,
                searchPlaceholder = "",
                isSingleSelect = false,
                toggleButtonsEnabled = false,
              }) => (
                <FilterAccordion
                  key={name}
                  title={title}
                  options={data || []}
                  searchEnabled={searchEnabled}
                  searchPlaceholder={searchPlaceholder}
                  selectedOptions={filterSelections[name] || []}
                  setSelectedOptions={(selectedOptions) =>
                    handleFilterChange(name, selectedOptions)
                  }
                  defaultExpanded={true}
                  isSingleSelect={isSingleSelect}
                  toggleButtonsEnabled={toggleButtonsEnabled}
                  toggleButtonGroupValue={toggleFilterSelections[name] || []}
                  setToggleButtonGroupValue={(newGroupValue) =>
                    handleToggleChange(name, newGroupValue)
                  }
                />
              )
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={state.checkedB}
                  onChange={handleChange}
                  name="checkedB"
                />
              }
              label="Only Show Overdue"
            />
          </Box>
        </Box>
      </Box>
    </Popover>
  );
};

export default WorkticketFiltersPopover;
