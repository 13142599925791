import React from "react";
const Context = React.createContext(null);

export const Provider = Context.Provider;
export const Consumer = Context.Consumer;

const AddDiscountDialogueContext = React.createContext();
const initialState = {
  isOpenAddDialog: false,
};
export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_CONTACT_DIALOG":
      return {
        ...state,
        isOpenAddDialog: action.open,
      };
    default:
      return state;
  }
};
const AddDiscountDialogProvider = ({ children }) => (
  <AddDiscountDialogueContext.Provider
    value={React.useReducer(reducer, initialState)}
  >
    {children}
  </AddDiscountDialogueContext.Provider>
);
const useAddDiscountModal = () => {
  const context = React.useContext(AddDiscountDialogueContext);
  if (context === undefined) {
    throw new Error(
      "useAddContactModal must be used within a AddContactDialogProvider"
    );
  }
  return context;
};

export { AddDiscountDialogProvider, useAddDiscountModal };
