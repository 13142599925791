import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    maxWidth: "100%",
  },
  rootContainer: {
    display: "flex",
    flexGrow: 1,
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 120px)",
    },
  },
  tableContainer: {
    display: "flex",
    flexGrow: 1,
    height: "100%",
    width: "50%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  header: {
    marginBottom: "10px",
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  headerLink: {
    color: "#4f98bc",
    fontWeight: "bold",
  },
  breadcrumbs: {
    border: "none",
    backgroundColor: "transparent",
  },
  loading: {
    textAlign: "center",
    margin: "40px 0",
    padding: "60px 20px",
    background: "#f4f4f4",
    borderRadius: "10px",
  },
  tablerow: {
    "&:hover": {},
    "& > td": {
      borderBottom: "solid 1px rgba(0, 0, 0, 0.05)",
    },
  },
  fullHeightTable: {
    flex: "1 1",
    display: "flex",
    maxHeight: "85%",
    maxWidth: "100%",
    overflowX: "hidden",
    "& table": {
      minWidth: 1200,
    },
  },
  tableRightPad: {
    marginRight: 94,
  },
  tablecell: {},
  type: {
    width: "110px !important",
    height: "auto !important",
    borderRadius: "3px !important",
    color: "#fff !important",
    fontSize: "10px !important",
    "& > span": {
      padding: "3px 15px !important",
      width: "100%",
      textAlign: "center",
      fontWeight: "700",
    },
    "& > svg": {
      color: "#fff !important",
    },
  },
  wrapperTable: {
    paddingTop: theme.spacing(2),
  },
  wrapperDialog: {
    textAlign: "center",
  },
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  pageBodyWrapper: {
    display: "flex",
    maxHeight: "100%",
    height: "100%",
    alignItems: "stretch",
    [theme.breakpoints.up("sm")]: {
      marginRight: 70,
    },
  },
  contentContainer: {
    padding: "0 20px",
    flex: "1 1",
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
  },

  detailRoot: {
    maxHeight: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    minWidth: "420px",
  },
  detailWrapper: {
    padding: "20px 50px 70px 20px",
    maxHeight: "100%",
    height: "100%",
    overflowY: "scroll",
  },
  detailTabbed: {
    borderTop: "solid 1px #ececec",
  },
  detailActionWrapper: {
    backgroundColor: "#f8f8f8",
    boxShadow: "0 0 4px 0 #dfdfdf",
    position: "absolute",
    width: "100%",
    bottom: 0,
    padding: "15px",
    display: "flex",
    justifyContent: "space-evenly",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "& > button": {
      margin: "0 5px",
      flexGrow: 1,
    },
  },
  loadingTable: {
    width: "97%",
    bottom: 0,
    position: "absolute",
  },
  contentCounter: {
    right: 88,
    position: "absolute",
    zIndex: 2,
    marginTop: -25,
    "& .MuiChip-outlined": {
      border: "none",
    },
    "& .MuiChip-label": {
      paddingRight: 0,
      fontWeight: "bold",
    },
    [theme.breakpoints.down("sm")]: {
      // left: 20,
      // top: 185
      position: "static",
      textAlign: "right",
      margin: 0,
      height: 30,
    },
  },
  tabItem: {
    display: "block",
  },
  exportAllButton: {
    display: "flex",
    width: 92,
    height: 32,
    padding: "8px 32px",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
    border: "1px solid  #4F98BC",
    background: "#FFF",
    color: "#4F98BC",
    textTransform: "capitalize",
    boxShadow: "none",
    "&:hover": {
      background: "#FFF",
      opacity: 0.8,
      boxShadow: "none",
    },
  },
  filtersDateMainContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 10,
  },
  dateRangeContainer: {
    marginLeft: 10,
    backgroundColor: "#EDF5F8",
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: 14,
    display: "flex",
    borderRadius: 4,
    justifyContent: "center",
    lineHeight: 1.5,
    "& p": {
      color: "#4f98bc",
      marginRight: 4,
      fontSize: 14,
    },
  },
  dateRangeAndCalendarPickerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  viewButton: {
    display: "flex",
    width: 55,
    height: 24,
    fontSize: 12,
    padding: "8px 32px",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
    border: "1px solid  #4F98BC",
    color: "#FFF",
    background: "#4F98BC",
    textTransform: "capitalize",
    boxShadow: "none",
    "&:hover": {
      background: "#4F98BC",
      opacity: 0.8,
      boxShadow: "none",
    },
  },
  idTableContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
  deleteDiscountButton: {
    display: "flex",
    fontSize: 14,
    fontWeight: 700,
    height: 32,
    padding: "8px 32px",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
    border: "1px solid  #4F98BC",
    background: "#FFF",
    color: "#4F98BC",
    textTransform: "capitalize",
    boxShadow: "none",
    "&:hover": {
      background: "#FFF",
      opacity: 0.8,
      boxShadow: "none",
    },
  },
  addDiscountButton: {
    display: "flex",
    fontSize: 14,
    fontWeight: 700,
    height: 32,
    padding: "8px 32px",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
    border: "1px solid  #4F98BC",
    background: "#4F98BC",
    color: "#FFF",
    textTransform: "capitalize",
    boxShadow: "none",
    "&:hover": {
      background: "#4F98BC",
      opacity: 0.8,
      boxShadow: "none",
    },
  },
  toolbarActionDiscount: {
    display: "flex",
    gap: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  dialogTitleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  dialogTitleWrapperComment: {
    display: "flex",
    width: "600px",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  dialogTitleInnerWrapper: {
    display: "flex",
    marginTop: 16,
    flexDirection: "row",
    alignItems: "center",
    gap: "12px",
    justifyItems: "center",
    "& svg": {
      color: "#4D4D4D",
    },
  },
  closeButton: {
    position: "absolute",
    right: "8px",
    top: "8px",
  },
  dialogTitle: {
    fontSize: 16,
    fontWeight: 700,
    textAlign: "center",
    paddingTop: 4,
    color: "#4D4D4D",
  },
  addContactFormWrapper: {
    display: "flex",
    maxWidth: "600px",
    flexDirection: "column",
    gap: 24,
  },
  addLocationFormWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
    maxWidth: "400px",
  },
  addQuestionButtonActionContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 16,
    padding: "0px 16px 16px 16px",
  },
  actionItemButton: {
    textAlign: "right",
    paddingTop: 24,
    paddingRight: 24,
    paddingBottom: 24,
  },
  editCommentButtonsContainer: {
    display: "flex",
    gap: 10,
    flexDirection: "row",
    alignItems: "center",
    textAlign: "right",
    //flex-end
    justifyContent: "flex-end",
    marginRight: 14,
    marginBottom: 4,
  },
  secondAddDiscountButton: {
    width: 155,
    height: 40,
    backgroundColor: "#4F98BC",
    padding: "8px, 32px, 8px, 32px",
    radius: "4px",
    lineHeight: "20px",
    textTransform: "none",
    color: "white",
    fontWeight: 600,
    fontSize: 14,
    "&.MuiButton-root:hover": {
      backgroundColor: "#4F98BC !important",
      opacity: 0.9,
    },
  },
  addDiscountCancelButton: {
    width: 115,
    height: 40,
    backgroundColor: "white",
    border: "1px solid #4F98BC",
    padding: "8px, 32px, 8px, 32px",
    radius: "4px",
    radiusColor: "#4F98BC",
    lineHeight: "20px",
    textTransform: "none",
    color: "#4F98BC",
    fontWeight: 600,
    fontSize: 14,
  },
  menuIcon: {
    width: 24,
    height: 24,
    flexShrink: 0,
    borderRadius: 4,
    background: "var(--Grey-200, #ECECEC)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  menuText: {
    paddingLeft: 8,
    color: "var(--Grey-400, #747474)",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  commentTitleInnerWrapper: {
    display: "flex",
    marginTop: 16,
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    justifyItems: "center",
    "& svg": {
      color: "#4D4D4D",
    },
  },
  titleIconWorkticketID: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    flexDirection: "column",
  },
  addCommentTitle: {
    fontSize: 14,
    fontWeight: 400,
    textAlign: "center",
    paddingTop: 4,
    color: "#747474",
  },
  commentContainer: {
    display: "flex",
    borderRadius: 4,
    paddingLeft: 40,
    backgroundColor: "#f8f8f8",
    flexDirection: "column",
    position: "relative",
    paddingBottom: 10,
  },
  profilePictureContainer: {},
  nameMenuActionsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 4,
  },
  userTitleText: {
    fontSize: 14,
    fontWeight: 600,
    color: "#747474",
  },
  editCommentTitleText: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 10,
    color: "#747474",
  },
  bodyCommentText: {
    fontSize: 14,
    fontWeight: 400,
    paddingRight: 8,
    color: "#747474",
  },
  questionTitleEdit: {
    fontSize: 14,
    fontWeight: 600,
    maxWidth: "100%",
    padding: "16px, 16px, 16px, 16px",
    borderRadius: "4px",
    "& .MuiTextField-root": {
      margin: "8px",
      width: "200px",
    },
    "& .MuiInputBase-input": {
      padding: "8px",
    },
  },
  writeCommentField: {
    fontSize: 14,
    fontWeight: 600,
    border: "1px solid #f8f8f8",
    maxWidth: "100%",
    padding: "16px, 16px, 16px, 16px",
    borderRadius: "1px",
    "& .MuiTextField-root": {
      margin: "8px",
      width: "200px",
    },
    "& .MuiInputBase-input": {
      padding: "8px",
    },
  },
  commentEditField: {
    width: "100%",
    // padding: "16px, 16px, 16px, 16px",
    paddingRight: 14,
    paddingTop: 10,
    paddingBottom: 14,
    borderRadius: "4px",
    backgroundColor: "#f8f8f8",
    "& .MuiInputBase-input": {
      padding: "8px",
    },
  },
  profilePicture: {
    width: 24,
    // height: 24,
    backgroundColor: "white",
    position: "absolute",
    left: "4px",
    top: "8px",
    borderRadius: "50%",
  },
  appBarWorkticketPreview: {
    position: "relative",
  },
  workticketModalMainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 32,
    margin: 32,
  },
  appBarWorkticketModalContainer: {
    // margin: 32,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  workticketPreviewButtonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
  },
  viewAllWorkticketsButton: {
    width: 180,
    height: 40,
    backgroundColor: "#4F98BC",
    padding: "8px, 32px, 8px, 32px",
    radius: "4px",
    lineHeight: "20px",
    textTransform: "none",
    color: "white",
    fontWeight: 600,
    fontSize: 14,
    "&.MuiButton-root:hover": {
      backgroundColor: "#4F98BC !important",
      opacity: 0.9,
    },
  },
  workticketPreviewTitle: {
    display: "flex",
    gap: 4,
    alignItems: "center",
    fontWeight: 700,
    fontSize: 16,
  },
  workticketPreviewTitleText: {
    fontWeight: 700,
    fontSize: 16,
    color: "#4D4D4D",
  },
  variousText: {
    fontSize: 14,
    color: "#747474",
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

export default useStyles;
