import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import classnames from "classnames";
import useStyles from "./styles";

const FilterAccordion = ({
  title,
  options = [],
  searchEnabled,
  searchPlaceholder,
  selectedOptions = [],
  setSelectedOptions,
  toggleButtonsEnabled = false,
  toggleButtonGroupValue = [],
  setToggleButtonGroupValue,
  defaultExpanded = false,
  isSingleSelect = false,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredOptions(options);
    } else {
      setFilteredOptions(
        options.filter((option) =>
          option.label.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, options]);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  const handleOptionToggle = (value) => {
    if (isSingleSelect) {
      setSelectedOptions([value]);
    } else {
      const currentIndex = selectedOptions.indexOf(value);
      const newSelectedOptions = [...selectedOptions];

      if (currentIndex === -1) {
        newSelectedOptions.push(value);
      } else {
        newSelectedOptions.splice(currentIndex, 1);
      }

      setSelectedOptions(newSelectedOptions);
    }
  };

  const handleButtonClick = (value) => {
    setToggleButtonGroupValue([value]);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleAccordionChange}
      className={classes.accordionRoot}
    >
      <AccordionSummary
        id="panel-content"
        aria-controls="panel-content"
        expandIcon={
          expanded ? (
            <KeyboardArrowDownOutlinedIcon className={classes.iconDown} />
          ) : (
            <ChevronRightIcon />
          )
        }
        className={classnames(classes.accordionSummary, {
          [classes.accordionSummaryExpanded]: expanded,
        })}
      >
        {title}
      </AccordionSummary>

      <AccordionDetails className={classes.accordionDetails}>
        {searchEnabled && (
          <TextField
            placeholder={searchPlaceholder}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            variant="outlined"
            InputProps={{
              disableunderline: "true",
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            className={classes.textField}
          />
        )}

        {toggleButtonsEnabled ? (
          <Box className={classes.filterButtonContainer}>
            {filteredOptions.map((option) => (
              <Button
                key={option.value}
                onClick={() => handleButtonClick(option.value)}
                className={classnames(
                  classes.toggleButton,
                  toggleButtonGroupValue.includes(option.value)
                    ? classes.activeButton
                    : classes.inactiveButton
                )}
              >
                {option.label}
              </Button>
            ))}
          </Box>
        ) : (
          <Box className={classes.checkListContainer}>
            {filteredOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    name={option.label}
                    checked={selectedOptions.includes(option.value)}
                    onChange={() => handleOptionToggle(option.value)}
                    className={classes.filterCheckbox}
                  />
                }
                label={option.label}
                className={classes.checkbox}
              />
            ))}
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default FilterAccordion;
