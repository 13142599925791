import React from "react";
const Context = React.createContext(null);

export const Provider = Context.Provider;
export const Consumer = Context.Consumer;

const AddCommentDialogue = React.createContext();
const initialState = {
  isOpenAddDialog: false,
};
export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_COMMENT_DIALOG":
      return {
        ...state,
        isOpenAddDialog: action.open,
      };
    default:
      return state;
  }
};
const AddCommentDialogProvider = ({ children }) => (
  <AddCommentDialogue.Provider value={React.useReducer(reducer, initialState)}>
    {children}
  </AddCommentDialogue.Provider>
);
const useAddCommentModal = () => {
  const context = React.useContext(AddCommentDialogue);
  if (context === undefined) {
    throw new Error(
      "useAddCommentModal must be used within a AddCommentDialogProvider"
    );
  }
  return context;
};

export { AddCommentDialogProvider, useAddCommentModal };
