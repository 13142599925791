import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import TableTabs from "components/common/Table/tableTabs";
import { StyledTab } from "components/common/Table/stylesTabs";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import Counter from "components/common/TableList/counter";
import WorkticketSoloStatusChip from "components/ui/Worktickets/WorkticketSoloStatusChip";
import LinearProgress from "@material-ui/core/LinearProgress";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import AccountPayablesFilters from "./accountPayablesSubWorkFilters";
import CalendarFilters from "components/common/Filters/calendarFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import { dateFormatField } from "components/util/timeFormat";
import useStyles from "./styles";

import { useTableState, useTableDispatch } from "contexts/tableContext";
import {
  useBillingState,
  useBillingDispatch,
  getBillingList,
  searchBilling,
  getBillingListDataSet,
} from "contexts/billingApSubContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";

const columns = [
  {
    id: "workticket_number",
    label: "Workticket",
    format: "id",
    checkbox: {
      active: (row) => false,
    },
  },
  {
    id: "workticket_summary",
    label: "WT Summary",
  },
  {
    id: "job",
    label: "Job",
    content: (row) => `${row.job_number} - ${row.job_description}`,
  },
  {
    id: "workticket.status",
    label: "WT Status",
    content: (row) => (
      <WorkticketSoloStatusChip status={row.workticket_status} archived={0} />
    ),
    disableSort: true,
  },
  { id: "workticket_start_date", label: "Due Date", format: "date" },
  {
    id: "workticket_completed_date",
    label: "Verified Date",
    content: (row) =>
      row.workticket_completed_date
        ? dateFormatField(row.workticket_completed_date)
        : `-`,
  },
  {
    id: "total_amount",
    label: "Payment",
    format: "money",
  },
];

const AccountPayablesSubWorkTable = (props) => {
  const classes = useStyles();
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const { billingListLoading, billingList, billingCount } = useBillingState();
  const { hasRowsClickable, selected } = useTableState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchBilling = useBillingDispatch();
  const dispatchFilters = useFilterDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatchBilling({
        type: "SET_LOADING",
        billingListLoading: true,
      });
      const filterData = getFilterData(filterState);
      await getBillingList(dispatchBilling, filterData);
    };

    if (filterState.searchString === "") {
      fetchData();
    }
  }, [dispatchBilling, filterState]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    dispatchTable({ type: "UPDATE_ROWS", rows: billingList });
  }, [dispatchTable, billingList]);

  const handleScrollClick = useCallback(async () => {
    if (billingCount <= billingList.length || loadingMore) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);

      await getBillingListDataSet(
        billingList,
        billingList.length,
        dispatchBilling,
        filterData,
        filterState.searchString
      );

      const billingDataUpdate = billingList ?? [];
      dispatchTable({ type: "UPDATE_ROWS", rows: billingDataUpdate });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    billingList,
    billingCount,
    filterState,
    dispatchBilling,
    dispatchTable,
    loadingMore,
  ]);

  const handleRowClick = (event, row) => {
    if (hasRowsClickable) {
      console.log("Click on row event");
    }
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          let results = await searchBilling(query, filterData);

          // Send result to search tab
          dispatchBilling({
            type: "SET_BILLING_LIST",
            billingList: results.list,
          });

          dispatchBilling({
            type: "SET_BILLING_COUNT",
            billingCount: results.count,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });

          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
  };

  const handleModuleChange = (event, newTab) => {
    const { history } = props;
    if (newTab === "0") {
      history.push(`/financial/report/account-payable-sub`);
    }
    if (newTab === "2") {
      history.push(`/financial/report/account-payable-sub-workticket`);
    }
  };

  return (
    <Box className={classes.root}>
      {billingListLoading || loadingSearch ? (
        <LoadingIndicator />
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <TableTabs handleTabChange={handleModuleChange} value="1">
              <StyledTab
                disableRipple={true}
                label={<Box className={classes.tabItem}>Checks</Box>}
                key="0"
                value="0"
              />
              <StyledTab
                disableRipple={true}
                label={<Box className={classes.tabItem}>Worktickets</Box>}
                key="1"
                value="1"
              />
              <StyledTab
                disableRipple={true}
                label={<Box className={classes.tabItem}>Payments</Box>}
                key="2"
                value="2"
              />
            </TableTabs>
            <Box className={classes.contentCounter}>
              <Counter>{`${billingList.length} / ${billingCount}`}</Counter>
            </Box>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      {selected.length > 0 ? (
                        <ToolbarSelected></ToolbarSelected>
                      ) : (
                        <ToolbarDefault
                          handleSearch={handleSearch}
                          handleSearchClear={handleSearchClear}
                        >
                          <CalendarFilters />
                          <AccountPayablesFilters />
                        </ToolbarDefault>
                      )}
                    </EnhancedTableToolbar>
                    {filterState.filters && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore ? (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(AccountPayablesSubWorkTable);
