export function OutlineReceiptIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.02344 9.86328L10.0234 5.86328M13.0234 3.53461V14.8633L10.5234 13.8633L8.02344 14.8633L5.52344 13.8633L3.02344 14.8633V3.53461C3.02344 2.79595 3.56082 2.16337 4.29456 2.07822C5.51781 1.93626 6.7621 1.86328 8.02344 1.86328C9.28477 1.86328 10.5291 1.93626 11.7523 2.07822C12.4861 2.16337 13.0234 2.79595 13.0234 3.53461ZM6.52344 6.36328H6.52844V6.36828H6.52344V6.36328ZM6.77344 6.36328C6.77344 6.50135 6.66151 6.61328 6.52344 6.61328C6.38537 6.61328 6.27344 6.50135 6.27344 6.36328C6.27344 6.22521 6.38537 6.11328 6.52344 6.11328C6.66151 6.11328 6.77344 6.22521 6.77344 6.36328ZM9.52344 9.36328H9.52844V9.36828H9.52344V9.36328ZM9.77344 9.36328C9.77344 9.50135 9.66151 9.61328 9.52344 9.61328C9.38537 9.61328 9.27344 9.50135 9.27344 9.36328C9.27344 9.22521 9.38537 9.11328 9.52344 9.11328C9.66151 9.11328 9.77344 9.22521 9.77344 9.36328Z"
        stroke="#747474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
