import React, { Component, Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ProtectedRoute from "./components/common/protectedRoute";
import PublicRoute from "./components/common/publicRoute";
import { ToastContainer } from "react-toastify";
import LoginForm from "./pages/loginPage/loginForm";
import ForgotPasswordForm from "./pages/loginPage/forgotPasswordForm";
import ResetPasswordForm from "./pages/loginPage/resetPasswordForm";
import CreatePasswordForm from "./pages/loginPage/createPasswordForm";
import Logout from "./pages/logout";
import PageNotFound from "./pages/pageNotFound";
import MainPage from "./pages/mainPage";
import WorkticketsList from "./pages/workticketsList/workticketsList";
import Workticket from "./pages/workticketPage/workticketPage";
import WorkticketPdf from "./pages/workticketPage/workticketPdfPage";
import WorkticketSurface from "./pages/workticketSurfacePage/workticketPage";
import WorkticketSurfacePdf from "./pages/workticketSurfacePage/workticketPdfPage";
import ProjectsList from "./pages/projectsList/projectsList";
import RequestsList from "./pages/requestsList/requestsList";
import AssetsList from "./pages/assetsList/assetsList";
import AssetsSurfaceList from "./pages/assetsSurfaceList/assetsSurfaceList";
import AssetsAdminList from "./pages/assetsAdminList/assetsList";
import AssetsCategoriesList from "./pages/assetsCategoriesList/assetsCategoriesList";
import ProjectPage from "./pages/projectPage/projectPage";
import QuotePage from "./pages/quotePage/quotePage";
import QuoteSurfacePage from "./pages/quoteSurfacePage/quoteSurfacePage";
import QuoteWTPlanning from "./pages/quotePage/quotePlanning";
import QuotesList from "./pages/quotesList/quotesList";
import QuotesSurfaceList from "pages/quotesSurfaceList/quotesSurfaceList";
import UsersList from "./pages/usersList/usersList";
import User from "./pages/userPage/userPage";
import RolesList from "./pages/rolesList/rolesList";
import Role from "./pages/rolePage/rolePage";
import CustomersList from "pages/adminCustomers/customersList";
import Customer from "./pages/customerPage/customerPage";
import JobsList from "./pages/jobsList/jobsList";
import Job from "./pages/adminJobPage/jobPage";
import JobBudgetsList from "./pages/jobBudgetsList/jobBudgetsList";
import TimekeepingList from "./pages/reportTimekeepingList/timekeepingList";
import AccountPayablesList from "./pages/reportAccountPayableList/accountPayablesList";
import AccountReceivableList from "./pages/reportAccountReceivableList/accountReceivableList";
import AccountPayablesSubList from "./pages/reportAccountPayableSubList/accountPayablesSubList";
import AccountPayablesSubWorkList from "./pages/reportAccountPayableSubWorkList/accountPayablesSubWorkList";
import QuoteAdmin from "./pages/quoteAdmin/quoteAdmin";
import RequestList from "./pages/requestsList/requestsList";
import RequestNonFacility from "./pages/requestNonFacility/requestNonFacility";
import BudgetsList from "./pages/budgetsList/budgetsList";
import BudgetsListPortfolio from "./pages/budgetsList/budgetsListPortfolio";
import Budget from "./pages/budgetPage/budgetPage";
import WorknovaList from "./pages/worknovaList/worknovaList";
import WorknovaDetailList from "./pages/worknovaDetailList/worknovaDetailList";
import NotesList from "pages/notesList/notesList";
import RingBellList from "pages/ringBellList/ringBellList";
import OpenPositions from "pages/openPositions/openPositions";
import ViewPositions from "pages/openPositions/viewPositions";
import FormsTab from "pages/formsTab/formsTab";
import SurveysQuestionsList from "pages/surveysQuestionsList/surveysQuestionsList";
import SurveysList from "pages/surveysList/surveysList";
import SurveysTasksList from "pages/surveysTasksList/surveysTasksList";
import TriggersList from "pages/triggersList/triggersList";
import Survey from "pages/survey";
import SurveyFrontLine from "pages/surveyFrontline";
import SurveyPage from "./pages/surveyPage/surveyPage";
import SurveysFrontlineList from "pages/surveysFrontlineList/surveysFrontlineList";
import SurveysFrontlineSubmit from "pages/surveysFrontlineSubmit/surveysFrontlineSubmit";
import SurveyViewPage from "pages/surveyViewPage/surveyViewPage";
import EvaluationsList from "pages/evaluationsList/evaluationsList";
import IndividualViewPage from "pages/individualViewPage/individualViewPage";
import LearnPage from "pages/learnPage/learnPage";
import PerformanceBoardPage from "pages/performanceBoardPage/performanceBoardPage";
import UnassignedJobs from "pages/adminCustomers/jobsList";
import DownloadMobileApp from "pages/downloadMobileApp";
import "react-toastify/dist/ReactToastify.css";
import PartnerPage from "./pages/partnerPage/partnerPage";
import PartnersList from "./pages/partnersList/partnersList";
import { FileComponentsPage } from "pages/fileComponents/components";
import { AccountPayableWorkticketTable } from "./pages/reportAccountPayableWorkticketsPartners/AccountPayableWorkticketTable";
import AccountPayablesWorkticketList from "./pages/reportAccountPayableWorkticketsPartners/accountPayableWorkticketList";
import AccountPayablesWorkticketListInternal from "./pages/reportAccountPayableWorkticketsInternal/accountPayableWorkticketListInternal";

const DashboardKpiPage = lazy(() =>
  import("pages/dashboardKpiPage/dashboardKpiPage")
);

const DashboardKpiLeaderboard = lazy(() =>
  import("pages/dashboardKpiPage/dashboardKpiLeaderboard")
);

const DashboardReportLabor = lazy(() =>
  import("pages/dashboardKpiPage/reports/labor/labor")
);

const AccountPayablesSuppliesList = lazy(() =>
  import("pages/reportAccountPayableSuppliesList/accountPayablesSuppliesList")
);

const AccountPayablesSuppliesItemList = lazy(() =>
  import(
    "pages/reportAccountPayableSuppliesList/accountPayablesSuppliesItemList"
  )
);

const OrgChartPage = lazy(() => import("pages/orgChartPage/orgChartPage"));
const OrgChartOpPage = lazy(() => import("pages/orgChartPage/orgChartOpPage"));

const GeneralBudgetList = lazy(() =>
  import("pages/budgets/generalBudget/generalBudgetList")
);
const SuppliesBudgetList = lazy(() =>
  import("pages/budgets/suppliesBudget/suppliesBudgetList")
);
class App extends Component {
  render() {
    return (
      <HelmetProvider>
        <ToastContainer />
        <Suspense fallback={"Loading Page"}>
          <Switch>
            <Route
              path="/onboard/welcome-sign-in"
              exact
              component={DownloadMobileApp}
            />
            <Route path="/logout" exact component={Logout} />
            <Route
              path="/request/:subdomain"
              exact
              component={RequestNonFacility}
            />

            <Route path="/" exact component={LoginForm} />
            <Route
              path="/forgot-password"
              exact
              component={ForgotPasswordForm}
            />
            <Route
              path="/reset-password/:email/:token"
              exact
              component={ResetPasswordForm}
            />
            <Route
              path="/create-password/:email/:token"
              exact
              component={CreatePasswordForm}
            />
            <PublicRoute
              path="/survey/public/:token"
              exact
              component={SurveysFrontlineSubmit}
            />
            <ProtectedRoute path="/survey" exact component={Survey} />
            <ProtectedRoute path="/dashboard" exact component={MainPage} />
            <ProtectedRoute
              path="/survey-frontline"
              exact
              component={SurveyFrontLine}
            />
            <ProtectedRoute path="/forms" exact component={FormsTab} />
            <ProtectedRoute path="/forms?talent" exact component={FormsTab} />
            <ProtectedRoute path="/forms?assets" exact component={FormsTab} />
            <ProtectedRoute path="/forms?accident" exact component={FormsTab} />
            <ProtectedRoute
              path="/worktickets"
              exact
              component={WorkticketsList}
            />
            <ProtectedRoute
              path="/workticket/surface/pdf/:id"
              exact
              component={WorkticketSurfacePdf}
            />
            <ProtectedRoute
              path="/workticket/surface/:id"
              exact
              component={WorkticketSurface}
            />
            <ProtectedRoute
              path="/workticket/pdf/:id"
              exact
              component={WorkticketPdf}
            />
            <ProtectedRoute
              path="/workticket/:id/:id_project?"
              exact
              component={Workticket}
            />
            <ProtectedRoute path="/projects" exact component={ProjectsList} />
            <ProtectedRoute path="/project/:id" exact component={ProjectPage} />
            <ProtectedRoute
              path="/quotes/surface"
              exact
              component={QuotesSurfaceList}
            />
            <ProtectedRoute path="/quotes" exact component={QuotesList} />
            <ProtectedRoute
              path="/quote/surface/:id"
              exact
              component={QuoteSurfacePage}
            />
            <ProtectedRoute
              path="/quote/cloned/:id/:cloned"
              exact
              component={QuotePage}
            />
            <ProtectedRoute
              path="/quote/:id/wtPlanning"
              exact
              component={QuoteWTPlanning}
            />
            <ProtectedRoute
              path="/quote/:id/:id_project?"
              exact
              component={QuotePage}
            />
            <ProtectedRoute
              path="/services-admin"
              exact
              component={QuoteAdmin}
            />
            <ProtectedRoute path="/requests" exact component={RequestsList} />
            <ProtectedRoute
              path="/assets/surface"
              exact
              component={AssetsSurfaceList}
            />
            <ProtectedRoute path="/assets" exact component={AssetsList} />
            <ProtectedRoute path="/users" exact component={UsersList} />
            <ProtectedRoute path="/user/:id" exact component={User} />
            <ProtectedRoute path="/roles" exact component={RolesList} />
            <ProtectedRoute path="/role/:id" exact component={Role} />
            <ProtectedRoute path="/customers" exact component={CustomersList} />
            <ProtectedRoute path="/customer/:id" exact component={Customer} />
            <ProtectedRoute
              path="/unassigned-jobs"
              exact
              component={UnassignedJobs}
            />
            <ProtectedRoute path="/jobs" exact component={JobsList} />
            <ProtectedRoute path="/job/:id" exact component={Job} />
            <ProtectedRoute
              path="/job/budgets/:id"
              exact
              component={JobBudgetsList}
            />
            <ProtectedRoute
              path="/assets/admin"
              exact
              component={AssetsAdminList}
            />
            <ProtectedRoute
              path="/assets/categories"
              exact
              component={AssetsCategoriesList}
            />
            <ProtectedRoute path="/requests" exact component={RequestList} />
            <ProtectedRoute
              path="/financial/report/timekeeping"
              exact
              component={TimekeepingList}
            />
            <ProtectedRoute
              path="/financial/report/account-payable"
              exact
              component={AccountPayablesList}
            />
            <ProtectedRoute
              path="/financial/report/account-payable-supplies/:id"
              exact
              component={AccountPayablesSuppliesItemList}
            />
            <ProtectedRoute
              path="/financial/report/account-payable-supplies"
              exact
              component={AccountPayablesSuppliesList}
            />
            <ProtectedRoute
              path="/financial/report/account-receivable"
              exact
              component={AccountReceivableList}
            />
            <ProtectedRoute
              path="/financial/report/account-payable-sub"
              exact
              component={AccountPayablesSubList}
            />
            <ProtectedRoute
              path="/financial/report/account-payable-sub-workticket"
              exact
              component={AccountPayablesWorkticketList}
            />
            <ProtectedRoute
              path="/financial/report/account-payable-internal-workticket"
              exact
              component={AccountPayablesWorkticketListInternal}
            />
            <ProtectedRoute
              path="/financial/report/account-payable-work-sub"
              exact
              component={AccountPayablesSubWorkList}
            />
            <ProtectedRoute path="/budgets" exact component={BudgetsList} />
            <ProtectedRoute
              path="/budgets/portfolio"
              exact
              component={BudgetsListPortfolio}
            />
            <ProtectedRoute
              path="/budget/:id/:type?"
              exact
              component={Budget}
            />
            <ProtectedRoute path="/worknova" exact component={WorknovaList} />
            <ProtectedRoute
              path="/worknova/detail/:id"
              exact
              component={WorknovaDetailList}
            />
            <ProtectedRoute path="/notes" exact component={NotesList} />
            <ProtectedRoute path="/team" exact component={RingBellList} />
            <ProtectedRoute
              path="/team/org-chart"
              exact
              component={OrgChartPage}
            />
            <ProtectedRoute
              path="/team/org-chart/operations"
              exact
              component={OrgChartOpPage}
            />
            <ProtectedRoute
              path="/team/:id"
              exact
              component={IndividualViewPage}
            />
            <ProtectedRoute
              path="/open-position/"
              exact
              component={OpenPositions}
            />
            <ProtectedRoute
              path="/open-position/:id"
              exact
              component={ViewPositions}
            />
            <ProtectedRoute
              path="/dashboard-kpi/leaderboard"
              exact
              component={DashboardKpiLeaderboard}
            />
            <ProtectedRoute
              path="/dashboard-kpi/report/labor"
              exact
              component={DashboardReportLabor}
            />
            <ProtectedRoute
              path="/dashboard-kpi/:type?"
              exact
              component={DashboardKpiPage}
            />
            <ProtectedRoute path="/survey/:id" exact component={SurveyPage} />
            <ProtectedRoute
              path="/surveys"
              exact
              component={SurveysFrontlineList}
            />
            <ProtectedRoute
              path="/survey/:survey_id/:job_id/:is_randomize?/:random_number?"
              exact
              component={SurveysFrontlineSubmit}
            />
            <ProtectedRoute
              path="/surveys/admin/surveys"
              exact
              component={SurveysList}
            />
            <ProtectedRoute
              path="/surveys/admin/questions"
              exact
              component={SurveysQuestionsList}
            />
            <ProtectedRoute
              path="/surveys/admin/tasks"
              exact
              component={SurveysTasksList}
            />
            <ProtectedRoute
              path="/surveys/admin/triggers"
              exact
              component={TriggersList}
            />
            <ProtectedRoute
              path="/surveys/admin/survey/:id"
              exact
              component={SurveyPage}
            />
            <ProtectedRoute
              path="/surveys/completed/:id"
              exact
              component={SurveyViewPage}
            />
            <ProtectedRoute
              path="/evaluations"
              exact
              component={EvaluationsList}
            />
            <ProtectedRoute path="/learn" exact component={LearnPage} />
            <ProtectedRoute path="/partners" exact component={PartnersList} />
            <ProtectedRoute path="/partner/:id" exact component={PartnerPage} />
            <ProtectedRoute
              path="/partner/:id/:mode"
              exact
              component={PartnerPage}
            />
            <ProtectedRoute
              path="/performance-board"
              exact
              component={PerformanceBoardPage}
            />
            <ProtectedRoute
              path="/jobs/budget/labor/:jobId"
              exact
              component={GeneralBudgetList}
            />
            <ProtectedRoute
              path="/jobs/budget/supplies/:jobId"
              exact
              component={SuppliesBudgetList}
            />
            <ProtectedRoute
              path="/components/files"
              exact
              component={FileComponentsPage}
            />
            <ProtectedRoute component={PageNotFound} />
          </Switch>
        </Suspense>
      </HelmetProvider>
    );
  }
}

export default App;
