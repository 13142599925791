export const insuranceComplianceFilter = [
  { value: 0, label: "Inactive" },
  { value: 1, label: "Active" },
];

export const ndaSignedFilter = [
  { value: 0, label: "Inactive" },
  { value: 1, label: "Active" },
];

export const ownershipOptions = [
  { value: 0, label: "Yes" },
  { value: 1, label: "No" },
];
export const partnerStatus = [
  { value: 1, label: "Active" },
  { value: 2, label: "Onboarding" },
  { value: 3, label: "Potential" },
  { value: 4, label: "Inactive" },
];

export const partnerRatingFilter = [
  { value: 1, label: "1+ Star Rating" },
  { value: 2, label: "2+ Star Rating" },
  { value: 3, label: "3+ Star Rating" },
  { value: 4, label: "4+ Star Rating" },
  { value: 5, label: "5  Star Rating" },
];
export const partnersTabOptions = [
  { id: 1, text: "All", tab: "all", onTabing: true },
  { id: 2, text: "Active", tab: "active", onTabing: true },
  { id: 3, text: "Onboarding", tab: "onboarding", onTabing: true },
  { id: 4, text: "Potential", tab: "potential", onTabing: true },
  { id: 5, text: "Inactive", tab: "inactive", onTabing: true },
];
