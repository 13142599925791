import React from "react";
const Context = React.createContext(null);

export const Provider = Context.Provider;
export const Consumer = Context.Consumer;

const WorkticketModal = React.createContext();
const initialState = {
  isWorkticketModalOpen: false,
};
export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_WORKTICKET_MODAL":
      console.log("TOGGLE_WORKTICKET_MODAL", action.open);
      return {
        ...state,
        isWorkticketModalOpen: action.open,
      };
    default:
      return state;
  }
};
const WorkticketModalProvider = ({ children }) => (
  <WorkticketModal.Provider value={React.useReducer(reducer, initialState)}>
    {children}
  </WorkticketModal.Provider>
);
const useWorkticketModal = () => {
  const context = React.useContext(WorkticketModal);
  if (context === undefined) {
    throw new Error(
      "useWorkticketModal must be used within a WorkticketModalProvider"
    );
  }
  return context;
};

export { WorkticketModalProvider, useWorkticketModal };
