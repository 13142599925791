export function CommentBubbleOutlineIcon() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 5.85591C14.0894 6.04554 14.5 6.60802 14.5 7.25403V10.1111C14.5 10.8688 13.9351 11.5114 13.1798 11.573C12.9539 11.5915 12.7273 11.6076 12.5 11.6214V13.6819L10.5 11.6819C9.5975 11.6819 8.70373 11.6451 7.82015 11.573C7.62128 11.5567 7.4356 11.5002 7.26996 11.4119M13.5 5.85591C13.3991 5.82343 13.2929 5.80189 13.1826 5.79274C12.2981 5.71932 11.4034 5.68188 10.5 5.68188C9.59655 5.68188 8.70186 5.71932 7.81739 5.79273C7.06333 5.85532 6.5 6.49737 6.5 7.25403V10.111C6.5 10.6692 6.80657 11.1649 7.26996 11.4119M13.5 5.85591V4.60676C13.5 3.52581 12.7322 2.58898 11.6602 2.44982C10.2986 2.27306 8.91002 2.18188 7.50019 2.18188C6.09022 2.18188 4.70155 2.27307 3.33976 2.44988C2.26782 2.58905 1.5 3.52587 1.5 4.60681V8.75698C1.5 9.83791 2.26782 10.7747 3.33976 10.9139C3.72441 10.9638 4.11119 11.007 4.5 11.0431V14.1819L7.26996 11.4119"
        stroke="#747474"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
